
<script setup lang = "ts">
    import { ref, watch, computed } from "vue";

    import MBottomSheet from "./components.BottomSheet.vue";
    import MContainer from "./components.Container.vue";
    import MContent from "./components.Content.vue";
    import MPill from "./components.Pill.vue";
    import MFieldDistinct from "./components.filter.fields.Distinct.vue";
    import MFieldRange from "./components.filter.fields.Range.vue";
    import MFieldSlider from "./components.filter.fields.Slider.vue";
    import MSavedFilters from "./components.filter.SavedFilters.vue";

    import { $t } from "o365-utils"

    const props = defineProps(["filter", "dataObject"]);
    const emit = defineEmits([]);

    const fields = computed(() => props.filter.fields.filter(e => e.type !== "Checkbox" && e.type !== "Filters"));
    const checkboxes = computed(() => props.filter.fields.filter(e => e.type === "Checkbox"));
    const filters = computed(() => props.filter.fields.filter(e => e.type === "Filters"));

    console.log("checkboxes", checkboxes);

    function getSortedFilters(filters) {
        return filters.toSorted((a, b) => {
            if (a.Default && !b.Default) {
                return -1;
            } else {
                return a.index - b.index;
            }
        });
    }

    /* reset field when show changes */
    watch(() => props.filter.showFieldSheet, () => {
        if (!props.filter.showFieldSheet) {
            props.filter.selectField(null);
            props.filter.isSavedFiltersShown = false;
        }
    });

    /* animation to swap between fields and selected field */
    const anim = ref(null);

    watch(() => [props.filter.field, props.filter.isSavedFiltersShown], ([val, savedFilters], [old, oldSavedFilters]) => {
        if (val || savedFilters ) {
            anim.value = "route-forward";
        } else {
            anim.value = "route-back";
            
            if (old && !old.noAutoSave) {
                old.applyTempValue();
            }
            
        }
    });

    /* handlers */
    function selectField(field) {
        props.filter.selectField(field);
        window.field = field;
    }

    function clearField(field) {
        field.clear();
        props.filter.update();
    }

</script>

<template>
    <MBottomSheet v-model="filter.showFieldSheet" v-bind="$attrs" min-height="calc(90% - 3rem)" max-height="calc(90% - 3rem)">
    <!-- <MBottomSheet v-model="filter.showFieldSheet" v-bind="$attrs" fullscreen> -->
        <template #title>
            <div class="d-flex justify-content-center align-items-center">
                <transition :name="anim">

                    <template v-if="!filter.isSavedFiltersShown">
                        <template v-if="filter.field">
                            <div style="position: absolute;">
                                {{ filter.field.name }}
                            </div>
                        </template>
                        <template v-else>
                            <div style="position: relative;">
                                {{ $t("Filter") }}
                                <div style="position: absolute; transform: translate(-50%, -50%); right: -2rem; top: 50%">
                                    <div v-if="filter.dataObject.state.isLoading" class="spinner-border spinner-border-sm" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>

                    <template v-else>
                        <div style="position: absolute;">
                            {{ $t("Saved Filters") }}
                        </div>
                    </template>

                </transition>
            </div>
        </template>
        <template #action>
            <transition name="fade">
                <template v-if="!filter.field">
                    <!-- v-if="dataObject?.filterObject" check hides saved filter button --> 
                    <!-- for prevent runtime error in apps which data source passed to Register component -->  
                    <!-- has no filterObject --> 
                    <template v-if="dataObject?.filterObject">
                        <template v-if="!filter.isSavedFiltersShown">
                            <a class="d-flex justify-content-start align-items-center" 
                                style="position: absolute; top: 0; left: 10px; bottom: 0; width: 120px"
                                @click="filter.isSavedFiltersShown = !filter.isSavedFiltersShown"
                            >
                                <div class="d-flex" style="width: 100%; justify-content: center; text-center">
                                    {{ $t("Saved Filters") }}
                                </div>
                            </a>
                        </template>

                        <template v-else>
                            <a class="d-flex justify-content-center align-items-center" 
                                style="height: 100%; aspect-ratio: 1; text-decoration: none;" 
                                @click="filter.isSavedFiltersShown = !filter.isSavedFiltersShown"
                            >
                                <i class="fas fa-chevron-left" />
                            </a>
                        </template>
                    </template>

                </template>
                <template v-else>
                    <a class="d-flex justify-content-center align-items-center" 
                        style="height: 100%; aspect-ratio: 1; text-decoration: none;" 
                        @click="filter.selectField(null)"
                    >
                        <i class="fas fa-chevron-left" />
                    </a>
                </template>
            </transition>
        </template>
        <template #body>
            <MContainer>
                <transition :name="anim">
                    <template v-if="filter.field?.type === 'Distinct'">
                        <MFieldDistinct :filter="filter" />
                    </template>
                    <template v-else-if="filter.field?.type === 'Range'">
                        <MFieldRange :filter="filter" />
                    </template>
                    <template v-else-if="filter.field?.type === 'Slider'">
                        <MFieldSlider :filter="filter" />
                    </template>
                    <template v-else-if = "filter.isSavedFiltersShown && dataObject?.filterObject">
                        <MSavedFilters 
                            :dataObject= "dataObject" 
                            :filter="filter" 
                        />
                    </template>
                    
                    <template v-else>
                        <MContainer>
                            <template v-if="filters.length || checkboxes.length">
                                <!-- main filter -->
                                <div class="px-3 mt-2 mb-2 d-flex gap-2 hide-scrollbar scrollable" style="overflow-x: auto;">
                                    <template v-for="(field) in filters">
                                        <div class="d-flex">

                                        <!-- working with this block --> 
                                            <template v-for="(filter, index) in getSortedFilters(field.filters)">
                                                <MPill :variant="field.isSelectedByFilter(filter) ? 'contained' : 'outlined'" 
                                                    size="md" 
                                                    color="primary" 
                                                    :first="index == 0" 
                                                    :last="index == (field.filters.length - 1)" 
                                                    @click="field.setValueByFilter(filter)"
                                                >
                                                    {{ filter.Title }}
                                                </MPill>
                                            </template>
                                        </div>
                                    </template>
                                </div>

                                <!-- checkboxes -->
                                <div class="px-3 mt-0 mb-2 d-flex gap-2 hide-scrollbar scrollable" style="overflow-x: auto;">
                                    <template v-if="checkboxes.length > 0">
                                        <div class="d-flex gap-2">
                                            <template v-for="(field, index) in checkboxes">
                                                <MPill :variant="field.isChecked() ? 'contained' : 'outlined'"
                                                    size="md"
                                                    color="primary" 
                                                    @click="field.toggle()"
                                                >
                                                    {{ field.getText() }}
                                                </MPill>
                                            </template>
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <MContent class="px-3 pb-3">
                                <template v-for="(field, index) in fields">
                                    <a class="d-flex align-items-center gap-2 list-item" 
                                        :class="{ 'border-top': index > 0 }" 
                                        style="color: black; padding: 0 1rem; text-decoration: none;" 
                                        @click="selectField(field)"
                                    >
                                        <div class="flex-shrink-0" style="padding: 0.75rem 0; font-size: 0.9em;">
                                            {{ field.name }}
                                        </div>
                                        <div class="flex-grow-1">
                                        </div>
                                        <template v-if="field.getFilterText()">
                                            <MPill style="min-width: 0;" 
                                                size="sm" 
                                                color="primary-subtle" 
                                                @close="clearField(field)"
                                            >
                                                {{ field.getFilterText() }}
                                            </MPill>
                                        </template>
                                        <div class="flex-shrink-0">
                                            <i class="far fa-chevron-right text-muted" style="font-size: 0.75em;" />
                                        </div>
                                    </a>
                                </template>
                            </MContent>
                        </MContainer>
                    </template>
                </transition>
            </MContainer>
        </template>
    </MBottomSheet>
</template>

<style>
    .list-item {
        background-color: white;

        transition: all 200ms ease-in-out;

        &:active {
            transition: all 0ms;
            background-color: rgb(95%, 95%, 95%);
        }
    }

    /* forward */
    .route-forward-enter-from {
        transform: translate(100%, 0%);
        opacity: 0%;
    }
    .route-forward-enter-active {
        transition: all 300ms ease-in-out;
    }
    .route-forward-enter-to {
        transform: translate(0%, 0%);
    }
    .route-forward-leave-from {
        transform: translate(0%, 0%);
    }
    .route-forward-leave-active {
        transition: all 300ms ease-in-out;
    }
    .route-forward-leave-to {
        transform: translate(-100%, 0%);
        opacity: 0%;
    }

    /* back */
    .route-back-enter-from {
        transform: translate(-100%, 0%);
        opacity: 0%;
    }
    .route-back-enter-active {
        transition: all 300ms ease-in-out;
    }
    .route-back-enter-to {
        transform: translate(0%, 0%);
    }
    .route-back-leave-from {
        transform: translate(0%, 0%);
    }
    .route-back-leave-active {
        transition: all 300ms ease-in-out;
    }
    .route-back-leave-to {
        transform: translate(100%, 0%);
        opacity: 0%;
    }

    .raise-enter-from {
        transform: translate(0, 100%);
        opacity: 0%;
    }
    .raise-enter-active {
        transition: all 200ms ease-in-out;
    }

    .raise-leave-active {
        transition: all 400ms ease-in-out;
    }
    .raise-leave-to {
        transform: translate(0, 100%);
        opacity: 0%;
    }
</style>
